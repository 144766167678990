import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from 'yoshi-flow-editor-runtime/tpa-settings';

type IStylesParams = {
  thankYou_textFontStyle: StyleParamType.Font;
  thankYou_titleFontStyle: StyleParamType.Font;
  thankYou_borderColor: StyleParamType.Color;
  thankYou_dividersColor: StyleParamType.Color;
  thankYou_textColor: StyleParamType.Color;
  thankYou_titleColor: StyleParamType.Color;
  thankYou_background: StyleParamType.Color;
  thankYou_borderWidth: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  thankYou_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  thankYou_titleFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  thankYou_borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  thankYou_dividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  thankYou_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thankYou_titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thankYou_background: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  thankYou_borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
});

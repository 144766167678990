import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {OrderDetailsSection} from '../OrderDetailsSection/OrderDetailsSection';
import settingsParams from '../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';

export enum OrderLabelDataHook {
  root = 'OrderLabelDataHook.root',
  orderNumberLabel = 'OrderLabelDataHook.orderNumberLabel',
  orderId = 'OrderLabelDataHook.orderId',
}

export function OrderLabel() {
  const {get} = useSettings();
  const {orderId} = useControllerProps().thankYouPageStore;

  return (
    <OrderDetailsSection dataHook={OrderLabelDataHook.root}>
      <OrderDetailsSection.Title dataHook={OrderLabelDataHook.orderNumberLabel}>
        {get(settingsParams.THANK_YOU_PAGE_ORDER_NUMBER_LABEL)}
      </OrderDetailsSection.Title>
      <OrderDetailsSection.Content dataHook={OrderLabelDataHook.orderId}>{orderId}</OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}

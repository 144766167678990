import React from 'react';
import s from './ThankYouPageApp.scss';
import {Header} from '../Components/Header/Header';
import {OrderLabel} from '../Components/OrderLabel/OrderLabel';
import {OrderPrice} from '../Components/OrderPrice/OrderPrice';
import {OfflinePayments} from '../Components/OfflinePayments/OfflinePayments';
import {useControllerProps} from '../ControllerContext';
import {ShippingInfo} from '../Components/ShippingInfo/ShippingInfo';
import {DigitalLinksError} from '../Components/DigitalLinksError/DigitalLinksError';
import {ContinueShopping} from '../Components/ContinueShopping/ContinueShopping';
import {StorePickup} from '../Components/StorePickup/StorePickup';
import {DigitalFiles} from '../Components/DigitalFiles/DigitalFiles';
import {SubscriptionLabel} from '../Components/SubscriptionLabel/SubscriptionLabel';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {THANK_YOU_PAGE_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';

export enum ThankYouPageAppDataHook {
  root = 'ThankYouPageAppDataHook.root',
  blank = 'ThankYouPageAppDataHook.blank',
}

function ThankYouPageApp() {
  const {ssrError, thankYouPageStore} = useControllerProps();
  if (ssrError) {
    return <div data-hook={ThankYouPageAppDataHook.blank} />;
  }

  const {
    shouldShowDigitalItems,
    shouldShowDigitalLinksError,
    shouldShowOfflinePayments,
    shouldShowShipping,
    shouldShowStorePickup,
    isSubscription,
    isValidOrder,
  } = thankYouPageStore;

  return (
    <main data-hook={ThankYouPageAppDataHook.root} className={s.root}>
      <Header />
      {shouldShowDigitalLinksError && <DigitalLinksError />}
      <div className={s.orderDetailsContainer}>
        <div className={s.section}>
          <ul>
            {isSubscription && (
              <li className={s.sectionItem}>
                <SubscriptionLabel />
              </li>
            )}
            {!isSubscription && isValidOrder && (
              <li className={s.sectionItem}>
                <OrderLabel />
              </li>
            )}
            <li className={s.sectionItem}>
              <OrderPrice />
            </li>
            {shouldShowOfflinePayments && (
              <li className={s.sectionItem}>
                <OfflinePayments />
              </li>
            )}
            {shouldShowStorePickup && (
              <li className={s.sectionItem}>
                <StorePickup />
              </li>
            )}
            {shouldShowShipping && (
              <li className={s.sectionItem}>
                <ShippingInfo />
              </li>
            )}
          </ul>
        </div>
        {shouldShowDigitalItems && (
          <div className={s.section}>
            <DigitalFiles />
          </div>
        )}
      </div>
      <ContinueShopping />
    </main>
  );
}

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(ThankYouPageApp, {
  dsn: THANK_YOU_PAGE_DSN,
  config: {environment: 'Native Component'},
});

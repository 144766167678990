import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {OrderDetailsSection, OrderDetailsSectionLayout} from '../OrderDetailsSection/OrderDetailsSection';
import settingsParams from '../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';

export enum ShippingInfoDataHook {
  root = 'ShippingInfoDataHook.root',
  label = 'ShippingInfoDataHook.label',
  shippingAddress = 'ShippingInfoDataHook.shippingAddress',
}

export function ShippingInfo() {
  const {get} = useSettings();
  const {shippingAddress} = useControllerProps().thankYouPageStore;

  return (
    <OrderDetailsSection dataHook={ShippingInfoDataHook.root} layout={OrderDetailsSectionLayout.Vertical}>
      <OrderDetailsSection.Title dataHook={ShippingInfoDataHook.label}>
        {get(settingsParams.THANK_YOU_PAGE_SHIPPING_ADDRESS_LABEL)}
      </OrderDetailsSection.Title>
      <OrderDetailsSection.Content dataHook={ShippingInfoDataHook.shippingAddress}>
        {shippingAddress}
      </OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}
